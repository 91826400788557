<template>
  <div class="becomeDoctor">
    <van-cell-group inset class="group">
      <van-cell class="input">
        <div class="left">
          <span>*</span>姓名
        </div>
        <van-field
          v-model="name"
          placeholder="请输入用姓名"
          class="input"
          :disabled="this.$store.state.user.buttonLoading"
        />
      </van-cell>
      <van-cell class="input between">
        <div class="left">
          <span>*</span>性别
        </div>
        <div>
          <van-radio-group
            v-model="sex"
            :disabled="this.$store.state.user.buttonLoading"
          >
            <van-radio name="1">男</van-radio>
            <van-radio name="2">女</van-radio>
          </van-radio-group>
        </div>
      </van-cell>
    </van-cell-group>
    <div class="buttonB">
      <van-button
        class="button"
        :disabled="!this.$store.state.user.name"
        @click="this.onBecomeDoctor"
        :loading="this.$store.state.user.buttonLoading"
      >申请成为医生
      </van-button>
    </div>
    <div class="loading black flex flex-x flex-y" v-if="this.$store.state.user.error">
      <div class="loading-box">
        <div class="load-bac">
          <img src="../../../images/false.png" alt=" " class="icon-img"/>
          <div class="load-explain">无成为医生权限</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  import { mapActions, mapMutations } from "vuex";

  export default {
    name: "setUserInfo",
    created: function () {

    },
    computed: {
      sex: {
        get () {
          return this.$store.state.user.sex;
        },
        set (v) {
          this.change ({ key: "sex", value: v });
        }
      },
      name: {
        get () {
          return this.$store.state.user.name;
        },
        set (v) {
          this.change ({ key: "name", value: v });
        }
      },
    },
    methods: {
      onBecomeDoctor () {
        this.onSubmitToBeDoctor (this);
      },
      ...mapMutations ("user", ["change"]),
      ...mapActions ("user", ["onSubmitToBeDoctor"])
    },
  };
</script>
<style lang="scss" scoped rel="stylesheet/scss">
  @import 'becomeDoctor';
</style>
